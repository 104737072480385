window.onscroll = function() { 
    //f_stickyMenu()
};

var header = document.getElementById("header");
var sticky = header.offsetTop;

function f_stickyMenu() {
    if (window.pageYOffset >= sticky) {
        document.body.classList.add("sticky")
    } else {
        document.body.classList.remove("sticky");
    }
}

function f_viewMenu() {
    document.body.classList.add("viewMenu")
}
function f_hideMenu() {
    document.body.classList.remove("viewMenu")
}

document.getElementById("montadito").addEventListener("click", f_viewMenu);
document.getElementById("close").addEventListener("click", f_hideMenu);

// ------------------------------------------------------
// DETECT TYPE DEVICE
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

if( isMobile.iOS() ) {
    document.body.classList.add("ios")
};
if( isMobile.Android() ) {
    document.body.classList.add("android")
};
// --------------------------------------------------------
// --------------------------------------------------------
// COOKIES

function getCookie(c_name){
    var c_value = document.cookie;
    var c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1){
            c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start == -1){
            c_value = null;
    }else{
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1){
            c_end = c_value.length;
            }
            c_value = unescape(c_value.substring(c_start,c_end));
    }
    return c_value;
}

function setCookie(c_name, value, exdays){
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
    document.cookie=c_name + "=" + c_value;
}

function checkCookie() {
    var cookie = getCookie("tiendaaviso");
    if (cookie == "" || cookie == null) {
            document.getElementById("barraaceptacion").style.display="block";
    }
}

function PonerCookie(){
    setCookie('tiendaaviso','1',365);
    document.getElementById("barraaceptacion").style.display="none";
}
checkCookie();



// --------------------------------------------------------
// --------------------------------------------------------
$(document).ready(function(){

    // SMOOTH ANCHOR LINKS
    $('.menu__link').click(function() {

        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
            && location.hostname == this.hostname) {

            var $target = $(this.hash);
            $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');

            if ($target.length) {

                if($('body').hasClass('viewMenu')){
                    $('body').removeClass('viewMenu');
                }

                var targetOffset = $target.offset().top - 65;
                $('html,body').animate({scrollTop: targetOffset}, 2000);
                return false;
            }
        }
    });

    // CONTACT MODAL
    var clickContact = true;

    $('.js-button-contact').click(function() {
        $('body').removeClass('viewMenu');
        setTimeout(function(){ 
            $('body').addClass('viewContact');
        }, 200);
    });

    $('#close-modal').click(function() {
        clickMenu = true;
        $('body').removeClass('viewContact');
    });
    
    $('.modal__content').click(function () {
        clickContact = true;
	});
	
	$(".modal__contact").click(function () { 
		if(!clickContact){
            $('body').removeClass('viewContact');
        }
		if($('body').hasClass('viewContact')){
            clickContact = false;
        }
    });
    
    // HOVER BANNERS
    $( ".js-banner" ).hover(
        function() {
            $( this ).addClass('active');
            $( this ).siblings().addClass('noactive');
        }, function() {
            $( this ).removeClass('active');
            $( this ).siblings().removeClass('noactive');
        }
    );

});
